import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/working_dir/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import { Input, Button } from '../../src';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "input"
    }}>{`Input`}</h1>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import Input from '@mfrm/mfcl/Input'
`}</code></pre>
    <h2 {...{
      "id": "description"
    }}>{`Description`}</h2>
    <p>{`Inputs accept user entry data. Inputs use labels and sometimes icons to communicate the input field that will accept data when a user submits them.`}</p>
    <h2 {...{
      "id": "properties"
    }}>{`Properties`}</h2>
    <Props of={Input} mdxType="Props" />
    <h2 {...{
      "id": "basic-usage"
    }}>{`Basic Usage`}</h2>
    <p>{`Generic attributes for input elements can be applied.`}</p>
    <Playground __position={1} __code={'<Input label=\"Default\" name=\"name\" />'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Input,
      Button,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Input label="Default" name="name" mdxType="Input" />
    </Playground>
    <h2 {...{
      "id": "size"
    }}>{`Size`}</h2>
    <p>{`You can choose between small, medium and large input field sizes, the default being large.`}</p>
    <Playground __position={2} __code={'<Input size=\"sm\" label=\"sm\" />\n<br />\n<Input size=\"md\" label=\"md\" />\n<br />\n<Input size=\"lg\" label=\"lg\" />'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Input,
      Button,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Input size="sm" label="sm" mdxType="Input" />
  <br />
  <Input size="md" label="md" mdxType="Input" />
  <br />
  <Input size="lg" label="lg" mdxType="Input" />
    </Playground>
    <h2 {...{
      "id": "required-or-disabled"
    }}>{`Required or Disabled`}</h2>
    <p>{`Make input required or disabled.`}<br parentName="p"></br>{`
`}{`Disabled attribute cascades to inline button.`}</p>
    <Playground __position={3} __code={'<Input type=\"text\" required label=\"required\" />\n<br />\n<Input type=\"text\" disabled label=\"disabled\" />'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Input,
      Button,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Input type="text" required label="required" mdxType="Input" />
  <br />
  <Input type="text" disabled label="disabled" mdxType="Input" />
    </Playground>
    <h2 {...{
      "id": "footnote"
    }}>{`Footnote`}</h2>
    <p>{`Add a small description below the input field.`}</p>
    <Playground __position={4} __code={'<Input inputMessage=\"This is an info message\" label=\"info\" />\n<br />\n<Input error inputMessage=\"This is an error message\" label=\"error\" />\n<br />\n<Input\n  disabled\n  error\n  inputMessage=\"This is an disabled error message\"\n  label=\"disabled error\"\n/>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Input,
      Button,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Input inputMessage="This is an info message" label="info" mdxType="Input" />
  <br />
  <Input error inputMessage="This is an error message" label="error" mdxType="Input" />
  <br />
  <Input disabled error inputMessage="This is an disabled error message" label="disabled error" mdxType="Input" />
    </Playground>
    <h2 {...{
      "id": "right-side"
    }}>{`Right Side`}</h2>
    <p>{`Add a button or another component to the right side`}</p>
    <Playground __position={5} __code={'<Input\n  size=\"sm\"\n  label=\"Right Side\"\n  rightSide={\n    <Button btnType=\"secondary\" style={{ height: \'100%\' }}>\n      Log Out\n    </Button>\n  }\n/>\n<br />\n<Input\n  size=\"md\"\n  label=\"Secondary Btn\"\n  rightSide={\n    <svg\n      viewBox=\"0 0 24 24\"\n      height={18}\n      width={18}\n      style={{ margin: \'13px 20px\' }}\n    >\n      <g\n        strokeLinecap=\"square\"\n        strokeLinejoin=\"miter\"\n        strokeWidth=\"2\"\n        fill=\"#2D2926\"\n        stroke=\"#2D2926\"\n      >\n        <line\n          x1=\"22\"\n          y1=\"22\"\n          x2=\"15.656\"\n          y2=\"15.656\"\n          fill=\"none\"\n          strokeMiterlimit=\"10\"\n        />\n        <circle\n          cx=\"10\"\n          cy=\"10\"\n          r=\"8\"\n          fill=\"none\"\n          stroke=\"#2D2926\"\n          strokeMiterlimit=\"10\"\n        />\n      </g>\n    </svg>\n  }\n/>\n<br />\n<Input\n  label=\"Tertiary Btn\"\n  rightSide={<Button style={{ height: \'100%\' }}>Submit</Button>}\n/>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Input,
      Button,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Input size="sm" label="Right Side" rightSide={<Button btnType="secondary" style={{
        height: '100%'
      }} mdxType="Button">
        Log Out
      </Button>} mdxType="Input" />
  <br />
  <Input size="md" label="Secondary Btn" rightSide={<svg viewBox="0 0 24 24" height={18} width={18} style={{
        margin: '13px 20px'
      }}>
        <g strokeLinecap="square" strokeLinejoin="miter" strokeWidth="2" fill="#2D2926" stroke="#2D2926">
          <line x1="22" y1="22" x2="15.656" y2="15.656" fill="none" strokeMiterlimit="10" />
          <circle cx="10" cy="10" r="8" fill="none" stroke="#2D2926" strokeMiterlimit="10" />
        </g>
      </svg>} mdxType="Input" />
  <br />
  <Input label="Tertiary Btn" rightSide={<Button style={{
        height: '100%'
      }} mdxType="Button">Submit</Button>} mdxType="Input" />
    </Playground>
    <h2 {...{
      "id": "with-default-values-set"
    }}>{`With default values set`}</h2>
    <Playground __position={6} __code={'<Input label=\"default\" defaultValue=\"Default Value\" />'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Input,
      Button,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Input label="default" defaultValue="Default Value" mdxType="Input" />
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      